@keyframes scanAnimation {
  0% {
    top: var(--scan-top-position);
  }
  50% {
    top: var(--scan-mid-position);
  }
  100% {
    top: var(--scan-bottom-position);
  }
}

.scan-line {
  width: 50px;
  height: 4px;
  background-color: white;
  animation: scanAnimation 4s linear infinite;
  position: absolute;
  top: calc(50% - 2px);
  left: calc(50% - 25px);
  clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
  z-index: 1000;
}
