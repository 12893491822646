.button {
  width: 180px;
  height: 45px;
  border-radius: 50px;
  font-size: 20px;
  color: white;
  margin-top: 30px;
  border: 2px solid black;
  background-color: black;
}

.button-disabled {
  background: transparent; /* Example background color */
  color: #909090; /* Example text color */
  border: 1px solid #909090; /* Example border color */
  cursor: not-allowed; /* Example cursor style */
}
